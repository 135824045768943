// Utility function to replace dynamic placeholders in translation keys and values
export const replaceDynamicValues = (translations, dynamicValue) => {
  const dynamicKeyPart = 'purchase-cash';
  const dynamicValuePart = dynamicValue.replace(/_/g, '-');

  return Object.keys(translations).reduce((acc, key) => {
    const newKey = key.replace(dynamicKeyPart, `purchase-cash-${dynamicValuePart}`);
    acc[newKey] = translations[key];
    return acc;
  }, {});
};

export const multilanguageListingTitle = (currentListing, currentLocale) => {
  const {
    title = '',
    publicData = {},
  } = currentListing.attributes;

  const titleData = {
    en: publicData.title,
    fr: publicData.title_fr,
    de: publicData.title_de,
    it: publicData.title_it,
    es: publicData.title_es,
  };

  const enhanceTitle = (title, titleTranslated) => {
    const finalTitle = titleTranslated ? titleTranslated : title; 
    return finalTitle;
  };

  return enhanceTitle(title, titleData?.[currentLocale])
};